import React from 'react'
import styles from './legal.module.sass'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import LegalNav from '../../components/legal-nav'

const PrivacyPage = () => (
   <Layout>
      <SEO title="Privacy Policy" />
      <div className={['page', styles.page__legal].join(' ')}>
         <LegalNav className={styles.legal__nav}></LegalNav>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               Privacy Policy
            </h1>
            <p
               className={['page--subheader', styles.page__subheader].join(
                  ' '
               )}>
               Our Privacy Policy document can be viewed{' '}
               <a
                  href="https://assets.ctfassets.net/wt3nvc89w481/764q9aMQyi3QG9W0SPxcvl/1204b52f04ac57ee625abaecbf394237/PrivacyPolicy-Oct2024.pdf"
                  rel="noopener noreferrer"
                  target="_blank">
                  here
               </a>
               .
            </p>
         </div>
      </div>
   </Layout>
)

export default PrivacyPage
