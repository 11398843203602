import React, { Component } from 'react'
import { Link } from 'gatsby'

import { Tabs, TabList } from 'react-tabs'

class LegalNav extends Component {
   render() {
      return (
         <section className="section__legal_nav">
            <div className="inner">
               <Tabs
                  className="l-tabs"
                  selectedTabClassName="l-tabs__tab--selected"
                  selectedTabPanelClassName="l-tabs__panel--selected">
                  <div className="tab--navigation">
                     <TabList className="l-tabs__tab-list">
                        <Link
                           to="/legal/disclaimers"
                           className="l-tabs__tab"
                           activeClassName="l-tabs__tab--selected">
                           Disclaimers + Disclosures
                        </Link>
                        <Link
                           to="/legal/terms"
                           className="l-tabs__tab"
                           activeClassName="l-tabs__tab--selected">
                           Terms of Use
                        </Link>
                        <Link
                           to="/legal/privacy"
                           className="l-tabs__tab"
                           activeClassName="l-tabs__tab--selected">
                           Privacy Policy
                        </Link>
                     </TabList>
                  </div>
               </Tabs>
            </div>
         </section>
      )
   }
}

export default LegalNav
